import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Styles from "./style.module.scss";
import Button from "../../Components/Button";

// Images
import UserImage3 from "../../Assets/Images/user_img3.png";
import UserImage4 from "../../Assets/Images/user_img4.png";
import UserImage5 from "../../Assets/Images/user_img5.png";
import UserImage6 from "../../Assets/Images/user_img6.png";
import UserImage7 from "../../Assets/Images/user_img7.png";
import UserImage8 from "../../Assets/Images/user_img8.png";

import BlogImage1 from "../../Assets/Images/blog_inner1.png";
import BlogImage2 from "../../Assets/Images/blog_inner2.png";
import BlogImage3 from "../../Assets/Images/blog_inner3.png";
import BlogImage4 from "../../Assets/Images/blog_img3.png";
import BlogImage5 from "../../Assets/Images/blog_img5.png";
import BlogImage6 from "../../Assets/Images/blog_img4.png";
import BlogImage7 from "../../Assets/Images/blog_img7.png";
import BlogImage8 from "../../Assets/Images/blog_img8.png";
import BlogImage9 from "../../Assets/Images/blog_img9.png";

const Blogs = [
  {
    id: 1,
    blogLink: "/blogdetail",
    image: BlogImage1,
    title: "The Evolution of Homeland Security",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations.",
    userImg: UserImage3,
    username: "Jotham willan",
    postdate: "01-Jan-2024",
  },
  {
    id: 2,
    blogLink: "/blogdetail",
    image: BlogImage2,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage4,
    username: "Alice john",
    postdate: "02-Jan-2024",
  },
  {
    id: 3,
    blogLink: "/blogdetail",
    image: BlogImage3,
    title: "Predictive Analytics and Beyond",
    description:
      "The integration of artificial intelligence into security systems represents a paradigm shift in how we perceive and manage security.",
    userImg: UserImage5,
    username: "Rotham alan",
    postdate: "03-Jan-2024",
  },
  {
    id: 4,
    blogLink: "/blogdetail",
    image: BlogImage4,
    title: "The Legalities of Armed Security",
    description:
      "Navigating the legal landscape of armed security requires a thorough understanding of regulations, licensing requirements.",
    userImg: UserImage6,
    username: "Elizbeth",
    postdate: "04-Jan-2024",
  },
  {
    id: 5,
    blogLink: "/blogdetail",
    image: BlogImage5,
    title: "The Evolution of Homeland Security",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations.",
    userImg: UserImage7,
    username: "Jack John",
    postdate: "05-Jan-2024",
  },
  {
    id: 6,
    blogLink: "/blogdetail",
    image: BlogImage6,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage8,
    username: "Jack John",
    postdate: "06-Jan-2024",
  },
  // {
  //   id: 7,
  //   blogLink: "#",
  //   image: BlogImage7,
  //   title: "Tips for a Productive Mind",
  //   description:
  //     "Explore practical techniques and habits to boost your creative thinking",
  //   userImg: UserImage3,
  //   username: "Jack John",
  //   postdate: "12-02-2024",
  // },
  // {
  //   id: 8,
  //   blogLink: "#",
  //   image: BlogImage8,
  //   title: "A Guide to Online Etiquette",
  //   description:
  //     "Explore practical techniques and habits to boost your creative thinking",
  //   userImg: UserImage4,
  //   username: "Jack John",
  //   postdate: "12-02-2024",
  // },
  // {
  //   id: 9,
  //   blogLink: "#",
  //   image: BlogImage9,
  //   title: " Wellness Tips for Professionals",
  //   description:
  //     "Explore practical techniques and habits to boost your creative thinking",
  //   userImg: UserImage5,
  //   username: "Jack John",
  //   postdate: "12-02-2024",
  // },
];

const BlogList = () => {
  const [message, setMessage] = useState(null);
  const [hideBtn, setHideBtn] = useState(true);

  const handleMessage = () => {
    setMessage("No more blogs available.");
    setHideBtn(false);
  };
  return (
    <Container className={Styles.blogListBox}>
      <h2 className={Styles.heading}>Recent Blog Posts</h2>
      <Row className={Styles.blogAdBox}>
        {Blogs.length &&
          Blogs.map((data, _idx) => (
            <Col md="4">
              <div className={Styles.longBlog} key={_idx}>
                <Link
                  to={data.blogLink}
                  state={{
                    blogId: data.id,
                  }}
                >
                  <img
                    className={Styles.blogImage}
                    src={data.image}
                    alt={data.title}
                  />
                </Link>
                <p className={Styles.title}>{data.title}</p>
                <p className={Styles.description}>{data.description}</p>
                <div className={Styles.userInfo}>
                  <img src={data.userImg} alt={data.username} />
                  <span className={Styles.username}>{data.username} . </span>
                  <span className={Styles.userdate}>{data.postdate}</span>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <div className={Styles.blogBtn}>
        {hideBtn && <Button title="Load more" onClick={handleMessage} />}
        {message !== null && <p className={Styles.message}>{message}</p>}
      </div>
    </Container>
  );
};

export default BlogList;
