import React, { Fragment } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import RequestForm from "./requestForm";
import Footer from "../../Components/Footer";

import Banner from "../../Assets/Images/reuest-service-banner.png";
import MobBanner from "../../Assets/mobileImages/reuest-service-banner.jpg";

const RequestServices = () => {
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        desktopBanner={Banner}
        mobileBanner={MobBanner}
        breadCrumb1="Home"
        breadCrumb2="Request Service"
        title="You can trust us"
        subtitle="Request a service quote - (713) 690-7866"
      />
      <RequestForm />
      <Footer />
    </Fragment>
  );
};

export default RequestServices;
