import React, { Fragment } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import Footer from "../../Components/Footer";

import SecurityBanner from "../../Assets/Images/security-camera-banner.jpg";
import SecurityMobBanner from "../../Assets/mobileImages/aviation-banner.jpg";
import Section1 from "./Section1";

const SecurityTrailer = () => {
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        normalImage
        desktopBanner={SecurityBanner}
        mobileBanner={SecurityMobBanner}
        breadCrumb1="Home"
        breadCrumb2="Security Camera Trailer"
        title=""
      />
      <Section1 />
      <Footer />
    </Fragment>
  );
};

export default SecurityTrailer;
