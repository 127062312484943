import React, { Fragment } from "react";
import HeaderWithText from "../../Components/HeaderWithText";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";

const About = () => {
  return (
    <Fragment>
      <Header />
      <HeaderWithText
        breadCrumb1="Home"
        breadCrumb2="About Us"
        title="Unveiling our Vison And Mission"
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Footer />
    </Fragment>
  );
};

export default About;
