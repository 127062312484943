import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import BlogPost from "./BlogPost";

const BlogDetailed = () => {
  const location = useLocation();

  return (
    <Fragment>
      <Header />
      <BlogPost id={location.state.blogId} />
      <Footer />
    </Fragment>
  );
};

export default BlogDetailed;
