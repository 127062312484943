import React, { useState } from "react";
import getWindowDimensions from "../../Utils/screenSize";
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import Styles from "./style.module.scss";
import Logo from "../../Assets/Images/logo.png";
import { Link } from "react-router-dom";
import Button from "../Button";

import Menu from "../../Assets/Images/menu.png";
import ArrowDown from "../../Assets/Images/dropdown-ico.png";

const Header = () => {
  const { width } = getWindowDimensions();

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [ddl, setDDL] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const handleMenu = () => {
    setOpen(!open);

    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };
  const handleDropdown = () => {
    setDDL(!ddl);
  };

  return width >= 1025 ? (
    <div className={Styles.outer_landing_page}>
      <Container className={Styles.desktopView} id="menu-top">
        <Row className="align-items-center">
          <Col md="1">
            <div className={Styles.left}>
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
          </Col>
          <Col md="9">
            <div className={Styles.center}>
              <div className={Styles.menuItems}>
                <div className={Styles.menuItem}>
                  <Link to="/">Home</Link>
                </div>
                <div className={Styles.menuItemOpt}>
                  <DropdownButton
                    className={Styles.servicesDDL}
                    title="Request Service"
                    id="services-request-ddl"
                    show={show}
                    onMouseEnter={showDropdown}
                    onMouseLeave={hideDropdown}
                  >
                    <Dropdown.Item>
                      <Link to="/services/#securityguarding">
                        Security Guarding
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/services/#remotevideo">
                        Remote Video Solutions
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/services/#aviation">Aviation Security</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/services/#electronic">
                        Electronic Security
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/services/#firesafety">Fire And Safety</Link>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <div className={Styles.menuItem}>
                  <Link to="/security">Security Camera Trailer</Link>
                </div>
                <div className={Styles.menuItem}>
                  <Link to="/about">About Us</Link>
                </div>
                <div className={Styles.menuItem}>
                  <Link to="/contact">Contact Us</Link>
                </div>
                <div className={Styles.menuItem}>
                  <Link to="/blogs">Blogs</Link>
                </div>
                <div className={Styles.menuItem}>
                  <Link to="/careers">Careers</Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md="2">
            <div className={Styles.right}>
              <Button link="/consultancy" title="Free Consultancy" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  ) : (
    <>
      <Container className={Styles.mobileView} id="menu-top">
        <Row className="align-items-center">
          <Col xs="1" sm="1" md="1">
            <Link to="/">
              <div className={Styles.left}>
                <img src={Logo} alt="Logo" />
              </div>
            </Link>
          </Col>
          <Col xs="9" sm="9" md="9">
            <div className={Styles.right}>
              <Button link="/consultancy" title="Free Consultancy" />
            </div>
          </Col>
          <Col xs="2" sm="2" md="2">
            <img
              src={Menu}
              alt="Menu Icon"
              onClick={handleMenu}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
      </Container>
      {open && (
        <div className={Styles.menuOptions}>
          <Container>
            <div className={Styles.menuItem}>
              <Link to="/">Home</Link>
            </div>
            <div className={Styles.menuItemOpt}>
              <div className={Styles.ddlmenu} onClick={handleDropdown}>
                <span className={Styles.menuItempt}>Request Service</span>
                <img src={ArrowDown} alt="arrow icon" />
              </div>
              {ddl && (
                <div className={Styles.menuDDL}>
                  <ul>
                    <li>
                      <Link to="/services#securityguarding">
                        Security Guarding
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#remotevideo">
                        Remote Video Solutions
                      </Link>
                    </li>
                    <li>
                      <Link to="/services#aviation">Aviation Security</Link>
                    </li>
                    <li>
                      <Link to="/services#electronic">Electronic Security</Link>
                    </li>
                    <li>
                      <Link to="/services#firesafety">Fire And Safety</Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={Styles.menuItem}>
              <Link to="/security">Security Camera Trailer</Link>
            </div>
            <div className={Styles.menuItem}>
              <Link to="/about">About Us</Link>
            </div>
            <div className={Styles.menuItem}>
              <Link to="/contact">Contact Us</Link>
            </div>
            <div className={Styles.menuItem}>
              <Link to="/blogs">Blogs</Link>
            </div>
            <div className={Styles.menuItem}>
              <Link to="/careers">Careers</Link>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default Header;
