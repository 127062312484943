import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeaderWithText from "../../Components/HeaderWithText";
import Styles from "./style.module.scss";

// Images
import UserImage3 from "../../Assets/Images/user_img3.png";
import UserImage4 from "../../Assets/Images/user_img4.png";
import UserImage5 from "../../Assets/Images/user_img5.png";
import UserImage6 from "../../Assets/Images/user_img6.png";
import UserImage7 from "../../Assets/Images/user_img7.png";
import UserImage8 from "../../Assets/Images/user_img8.png";

import BlogImage1 from "../../Assets/Images/blog_img1.png";
import BlogImage2 from "../../Assets/Images/blog_img2.png";
import BlogImage3 from "../../Assets/Images/blog_img3.png";
import BlogImage4 from "../../Assets/Images/blog_img4.png";
import BlogImage5 from "../../Assets/Images/blog_img5.png";
import BlogImage6 from "../../Assets/Images/blog_img6.png";

import Image from "../../Assets/Images/service-img3.png";
import { Link } from "react-router-dom";

const Blogs = [
  {
    id: 1,
    image: BlogImage1,
    title: "The Evolution of Homeland Security: From Past to Present",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations, adapting to emerging threats and evolving technologies. The journey from its early beginnings to its current state is a testament to the tireless efforts of dedicated professionals committed to protecting our nation. Let's take a brief look at the evolution of homeland security, highlighting key milestones and shifts in focus along the way.",
    userImg: UserImage3,
    username: "Jotham willan",
    postdate: "01-Jan-2024",
  },
  {
    id: 2,
    image: BlogImage2,
    title:
      "The Importance of Home Security: Why Every Household Needs a Comprehensive System",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently shows that homes equipped with visible security measures are significantly less likely to be targeted by burglars or intruders. The mere presence of alarms, cameras, and signage serves as a powerful deterrent, discouraging potential threats before they even attempt to breach your property.",
    userImg: UserImage4,
    username: "Alice john",
    postdate: "02-Jan-2024",
  },
  {
    id: 3,
    image: BlogImage3,
    title:
      "The Impact of Artificial Intelligence on Security Systems: Predictive Analytics and Beyond",
    description:
      "The integration of artificial intelligence into security systems represents a paradigm shift in how we perceive and manage security. By harnessing the power of predictive analytics, automation, and intelligent monitoring, AI empowers security systems to anticipate, detect, and mitigate threats proactively, thereby enhancing safety and security for individuals, organizations, and communities alike. As AI technology continues to evolve, the potential for innovation and advancement in security systems is limitless, promising a future where security is not just reactive but predictive and preventive.",
    userImg: UserImage5,
    username: "Rotham alan",
    postdate: "03-Jan-2024",
  },
  {
    id: 4,
    image: BlogImage4,
    title:
      "The Legalities of Armed Security: Regulations, Licenses, and Use of Force Guidelines ",
    description:
      " Navigating the legal landscape of armed security requires a thorough understanding of regulations, licensing requirements, and use of force guidelines. By prioritizing compliance, accountability, and risk management, armed security professionals and their employers can uphold legal standards while fulfilling their critical role in protecting people and property.",
    userImg: UserImage6,
    username: "Elizbeth",
    postdate: "04-Jan-2024",
  },
  {
    id: 5,
    image: BlogImage5,
    title: "The Evolution of Homeland Security",
    description:
      "Since its inception, the landscape of homeland security has undergone significant transformations.",
    userImg: UserImage7,
    username: "Jack John",
    postdate: "05-Jan-2024",
  },
  {
    id: 6,
    image: BlogImage6,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive security system is deterrence. Research consistently",
    userImg: UserImage8,
    username: "Jack John",
    postdate: "06-Jan-2024",
  },
];

const sideBlogs = [
  {
    id: 1,
    image: BlogImage1,
    title: "The Evolution of Homeland Security",
    description: "Since its inception, the landscape of homeland security",
    userImg: UserImage3,
    username: "Jotham willan",
    postdate: "01-Jan-2024",
  },
  {
    id: 2,
    image: BlogImage2,
    title: "The Importance of Home Security",
    description: "One of the key reasons every household needs a comprehensive",
    userImg: UserImage4,
    username: "Alice john",
    postdate: "02-Jan-2024",
  },
  {
    id: 3,
    image: BlogImage3,
    title: "Predictive Analytics and Beyond",
    description:
      "The integration of artificial intelligence into security systems.",
    userImg: UserImage5,
    username: "Rotham alan",
    postdate: "03-Jan-2024",
  },
  {
    id: 4,
    blogLink: "/blogdetail",
    image: BlogImage4,
    title: "The Legalities of Armed Security",
    description: "Navigating the legal landscape of armed security.",
    userImg: UserImage6,
    username: "Elizbeth",
    postdate: "04-Jan-2024",
  },
  {
    id: 5,
    blogLink: "/blogdetail",
    image: BlogImage5,
    title: "The Evolution of Homeland Security",
    description: "Since its inception, the landscape of homeland security.",
    userImg: UserImage7,
    username: "Jack John",
    postdate: "05-Jan-2024",
  },
  {
    id: 6,
    blogLink: "/blogdetail",
    image: BlogImage6,
    title: "The Importance of Home Security",
    description:
      "One of the key reasons every household needs a comprehensive.",
    userImg: UserImage8,
    username: "Jack John",
    postdate: "06-Jan-2024",
  },
];

const BlogPost = (props) => {
  const { id } = props;

  const [blog, setBlog] = useState({});

  useEffect(() => {
    let selectedBlog = Blogs.filter((data) => data.id === id);

    setBlog(selectedBlog);
  }, [id]);

  return (
    <Container className={Styles.blogDetailBox}>
      <HeaderWithText
        title="Recent Blog"
        breadCrumb1="Blogs"
        breadCrumb2="Blog Detail"
      />
      <Row>
        <Col md="8">
          {blog.length > 0 &&
            blog.map((data, _idx) => (
              <>
                <Row
                  className={`align-items-center ${Styles.blogInnerHeading}`}
                >
                  <Col md="6">
                    <div className={Styles.userInfo}>
                      <img src={data.userImg} alt={data.title} />
                      <span className={Styles.userText}>{data.username}</span>
                    </div>
                  </Col>
                  <Col md="6">
                    <span className={Styles.postDate}>{data.postdate}</span>
                  </Col>
                </Row>
                <h1 className={Styles.blogHeading}>{data.title}</h1>
                <div className={Styles.blogBanner}>
                  <img src={Image} alt={data.title} />
                </div>
                <p className={Styles.blogDescription}>{data.description}</p>
              </>
            ))}
        </Col>
        <Col md="4">
          {sideBlogs.length > 0 &&
            sideBlogs.map((data, _idx) => (
              <Row className={Styles.sideBlogBox}>
                <Col md="4">
                  <Link to={data.blogLink} state={{ blogId: data.id }}>
                    <img
                      src={data.image}
                      alt={data.id}
                      className={Styles.image}
                    />
                  </Link>
                </Col>
                <Col md="8">
                  <p className={Styles.heading}>{data.title}</p>
                  <p className={Styles.description}>{data.description}</p>
                  <div className={Styles.userBox}>
                    <div className={Styles.boxInfo}>
                      <img src={data.userImg} alt={data.title} />
                      <span className={Styles.username}>{data.username}</span>
                    </div>
                    <span className={Styles.userDate}>{data.postdate}</span>
                  </div>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Container>
  );
};

export default BlogPost;
