import React, { Fragment } from "react";
import Header from "../../Components/Header";
import HeaderWithImage from "../../Components/HeaderWithImage";
import Footer from "../../Components/Footer";
import getWindowDimensions from "../../Utils/screenSize";

import Banner from "../../Assets/Images/blog_banner.jpg";
import MobBanner from "../../Assets/mobileImages/blogs-banner.jpg";
import BlogList from "./bloglist";

const Blogs = () => {
  const { width } = getWindowDimensions();
  return (
    <Fragment>
      <Header />
      <HeaderWithImage
        desktopBanner={Banner}
        mobileBanner={MobBanner}
        breadCrumb1="Home"
        breadCrumb2="Blogs"
        title="The Backbone of Security: Understanding the Role of Surveillance and Patrol Services with Homeland Protect"
        subtitleSmall="In the realm of security services, two crucial elements often stand out as the unsung heroes: surveillance systems and patrolling guards. While VIP protection understandably garners attention, it's the meticulous work of surveillance cameras and diligent patrol personnel that truly fortify the safety net around events, government figures, and various settings"
      />
      <BlogList />
      <Footer />
    </Fragment>
  );
};

export default Blogs;
